<template>
  <section class="login">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper">
        <div class="content-wrapper d-flex align-items-center auth">
          <div class="row w-100">
            <div
              class="login-form-width col-lg-5 col-md-8 col-sm-9 col-xs-10 mx-auto"
            >
              <div class="auth-form-light text-left p-5">
                <div class="brand-logo">
                  <img src="../assets/images/onlypay/logo.svg" />
                </div>
                <h4>Olá, bem vindo de volta.</h4>
                <h6 class="font-weight-light">Faça login para continuar.</h6>
                <base-form class="pt-3" @onSubmit="login">
                  <base-input
                    v-model="userName"
                    fieldName="CPF"
                    mask="###.###.###-##"
                    placeholder="CPF"
                    messageError="Por favor insira um usuário válido"
                    validate="required|cpf"
                  />
                  <base-password
                    v-model="password"
                    fieldName="senha"
                    placeholder="Senha"
                    required
                  />
                  <div class="mt-3">
                    <button
                      class="btn btn-block btn-gradient-success btn-lg font-weight-medium auth-form-btn text-white"
                      :is-loading="isLoadingLogin"
                      :disabled="isLoadingLogin"
                      :status="statusLogin"
                      type="submit"
                    >
                      <div
                        v-if="isLoadingLogin"
                        class="spinner-border spinner-border-sm mr-2"
                      ></div>
                      <span v-if="isLoadingLogin">Entrando</span>
                      <span v-else-if="!userLogged">Entrar</span>
                    </button>
                  </div>
                </base-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { setCookie, getCookie, removeCookie } from '@/helpers/cookie';
import { setUserDetails } from '@/helpers/user';

export default {
  name: 'LoginForm',
  data() {
    return {
      userName: '',
      password: '',
      isLoadingLogin: false,
      userLogged: false,
      statusLogin: '',
      emailError: '',
      message: '',
      expiredToken: getCookie('olp_csl_expired_token'),
    };
  },
  methods: {
    login() {
      this.isLoadingLogin = true;

      this.$http
        .post('/login', {
          UserName: this.userName,
          pwd: this.password,
          TenantId: process.env.VUE_APP_TENANT_ID,
          UserIp: '198.162.6.2',
        })
        .then(({ data }) => {
          setCookie('token', data.token, 23);
          setCookie('userId', data.userId);
          this.isLoadingLogin = false;
          this.userLogged = true;
          this.setStatusLogin(true);
          this.$router.push({ name: 'home' });
          setUserDetails(JSON.stringify({ name: 'User', id: data.userId }));
        })
        .catch((err) => {
          if (!err.response) {
            this.$swal.fire({
              title: 'Oops!',
              html: 'Erro ao tentar se conectar com o servidor. <br/> Por favor, verifique se você está conectado a internet e se está conectado a VPN.',
              icon: 'error',
              confirmButtonText: 'Ok',
            });
            return;
          }
          this.userLogged = false;
          this.$swal({
            title: 'Erro!',
            text:
              (err.response.data && err.response.data.message) ||
              'Usuário ou senha inválidos',
            icon: 'error',
            confirmButtonText: 'Ok',
          });
          this.message =
            (err.response.data && err.response.data.message) ||
            'Usuário ou senha inválidos';
        })
        .finally(() => {
          this.isLoadingLogin = false;
        });
    },
    setStatusLogin(status) {
      this.statusLogin = status;
      setTimeout(() => {
        this.statusLogin = '';
      }, 1200);
    },
  },
  beforeMount() {
    if (this.expiredToken) {
      this.$swal
        .fire({
          title: 'Sessão expirada!',
          html: 'Sua sessão expirou. Por favor, faça login novamente.',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
        .then((result) => {
          if (result.isConfirmed) {
            removeCookie('olp_csl_expired_token');
          }
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.login {
  .content-wrapper {
    padding: 0;
  }
  .login-form-width {
    max-width: 500px;
    @media (min-width: 992px) and (max-width: 1200px) {
      width: 50% !important;
    }
  }
  .row {
    margin: auto !important;
  }
}
</style>

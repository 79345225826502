import axios from 'axios';
import { getCookie, removeCookie, setCookie } from '@/helpers/cookie';
import swal from 'sweetalert2';
import router from '@/router';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

instance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${getCookie('token')}`;

    if ((config.url || '').match(/^\//))
      config.headers['token'] = getCookie('token');
    else delete config.headers['token'];

    config.headers['Cache-Control'] = 'no-cache';
    config.headers['Pragma'] = 'no-cache';
    config.headers['Expires'] = '0';

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (res) => {
    const { data } = res;
    if (!data) return false;

    return res;
  },
  async (err) => {
    if (err.response.status === 401) {
      removeCookie('token');
      removeCookie('tenantId');
      removeCookie('userId');
      setCookie('olp_csl_expired_token', true, 96);

      window.location.href = router.resolve({ name: 'login' }).href;

      return Promise.reject(err);
    }

    if (!err.response) {
      swal.fire({
        title: 'Oops!',
        html: 'Erro ao tentar se conectar com o servidor. <br/> Por favor, verifique se você está conectado a internet e se está conectado a VPN.',
        icon: 'error',
        confirmButtonText: 'Ok',
      });

      return Promise.reject({
        ...err,
        response: {
          data: {
            message:
              'Erro ao tentar se conectar com o servidor. Por favor, verifique se você está conectado a internet e se está conectado a VPN.',
          },
        },
      });
    }
    return Promise.reject(err);
  }
);

export default instance;
